<style>
body {
  background-color: black;
}
h2,
h1,
h3,
h4,
h5 {
  color: white;
}
.card card-body {
  display: flex;
  overflow-x: auto;
}
</style>

<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
// import { Carousel, Slide } from "vue-carousel";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "บอร์ดต้อนรับ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    // Multiselect,
    // DatePicker,
    // Carousel,
    // Slide,
  },
  data() {
    return {
      title: "บอร์ดต้อนรับ",
      items: [
        {
          text: "บริการ",
        },
        {
          text: "บอร์ดต้อนรับ",
          active: true,
        },
      ],

      loading: undefined,
      form: {},
      branchId: "",

      localDataBranch: [], //เป็น-array Id
      localData: [],
      DateSearch: "",
      rowSlot: [],
      rowStatusBoard: [],
      countRowSlot: "",
      colSp: 4,
      fslot1: false,
      Col1: false,
      nowTime: "",
    };
  },
  computed: {},
  mounted() {
    this.nowTimes();
  },

  created() {
    this.getNow();
    this.nowTimes();
    this.getLocalData();
    this.getDataStatusBoard();
  },

  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;

      // console.log(user);
      // console.log(this.branchId);
    },

    getDataStatusBoard: function() {
      this.loading = true;
      useNetw
        .get("api/appointment", {
          params: {
            branchId: [this.branchId],
            page: 1,
            perPage: 100,
            apptDate: this.DateSearch,
            status: "CONF",
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowStatusBoard = response.data.data;
          // console.log("rowStatusBoard ====== > ", this.rowStatusBoard);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.DateSearch = date;
      // console.log(this.DateSearch);
    },
    timeFormate(timeStamp) {
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      this.nowTime = hh + ":" + mm;
    },

    nowTimes() {
      this.timeFormate(new Date());
      setInterval(this.nowTimes, 30 * 1000);
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <!-- <PageHeader :title="title" :items="items" /> -->
  <div class="row">
    <div class="col-12">
      <div class="card text-white" style="background-color:black">
        <div class="card-body">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-skeleton-table
                :rows="5"
                :columns="6"
                :table-props="{ bordered: false, striped: true }"
                animation="throb"
              ></b-skeleton-table>
            </template>
            <div class="row">
              <div class="col-md-11 text-end">
                  <!-- <a>พิมพ์เอกสารการนัดหมายประจำวัน</a> -->
                <!-- <button class="btn btn-primary">พิมพ์เอกสาร</button> -->
              </div>
              <div class="col-md-1 text-end">
                <router-link :to="{ name: 'home' }">
                  <a>กลับสู่หน้าหลัก</a>
                </router-link>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card text-white" style="background-color:black">
                  <div class="card-body text-white text-center">
                    <h4>
                      <i>{{ DateSearch }} : {{ nowTime }}</i>
                    </h4>
                    <br />
                    <h1 style="color:white;"><b> Welcome </b></h1>
                    <!-- <h2> Suriya IT </h2> -->
                    <p class="card-title-desc">
                      สถานะรถเข้าศุูนย์บริการ
                    </p>
                  </div>
                </div>

                <div class="table-responsive ">
                  <table class="table mb-0 text-white">
                    <thead style="color:white;">
                      <tr
                        v-for="(itemSTB, index) in rowStatusBoard"
                        :key="index"
                      >
                        <td>
                          <h1>
                            <b> {{ itemSTB.apptTime }} </b>
                          </h1>
                        </td>
                        <td>
                          <h2>{{ itemSTB.customerName }}</h2>
                          <i
                            >{{ itemSTB.vehiclePlate }} ,
                            {{ itemSTB.vehicleBrandEn }} -
                            {{ itemSTB.vehicleModelEn }}</i
                          >
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <!-- End .row -->
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>
